var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',{staticStyle:{"width":"calc(100% - 409px)"}},[_c('Alert-Warning')],1),_c('section',{staticClass:"d-flex flex-column",staticStyle:{"max-width":"616px","gap":"16px","margin-top":"16px"}},[_c('div',{staticClass:"upload-box"},[(_vm.payload.image != '')?_c('img',{attrs:{"src":_vm.payload.image,"alt":"","height":"110px","width":"110px"}}):_c('span',{staticClass:"font-12"},[_vm._v("Gunakan .png")]),_c('v-btn',{staticClass:"text-capitalize",staticStyle:{"background-color":"#f9f9f9","color":"#ff4265","border-color":"#eeeeee","font-size":"12px","font-weight":"700","letter-spacing":"0"},attrs:{"small":"","outlined":"","loading":_vm.isLoadingUpload},on:{"click":function($event){return _vm.handleUpload('upload-account-admin')}}},[_c('icon-upload'),_vm._v(" Upload Badge")],1),_c('upload-oss',{staticStyle:{"display":"none"},attrs:{"id":"upload-account-admin","typeAllowed":['png']},on:{"response":_vm.getResponse}})],1),_c('div',[_c('span',{staticClass:"font-12 font-weight-500"},[_vm._v("Nama Badge")]),_c('k-input',{attrs:{"placeholder":"Masukkan judul donasi","errorMessage":"Tidak lebih dari 40 karakter","rules":"max:40","counter":40},model:{value:(_vm.payload.name),callback:function ($$v) {_vm.$set(_vm.payload, "name", $$v)},expression:"payload.name"}})],1),_c('section',{staticClass:"d-flex justify-space-between align-center"},[_c('div',{staticStyle:{"width":"302px"}},[_c('CurrencyInput',{attrs:{"label":"Terendah","options":{
              currency: 'IDR',
              locale: 'id',
              currencyDisplay: 'hidden',
              hideGroupingSeparatorOnFocus: false,
              valueRange: {
                min: 1,
              },
            }},model:{value:(_vm.payload.lowest),callback:function ($$v) {_vm.$set(_vm.payload, "lowest", $$v)},expression:"payload.lowest"}})],1),_c('div',{staticStyle:{"width":"302px"}},[_c('CurrencyInput',{attrs:{"label":"Tertinggi","options":{
              currency: 'IDR',
              locale: 'id',
              currencyDisplay: 'hidden',
              hideGroupingSeparatorOnFocus: false,
            }},model:{value:(_vm.payload.highest),callback:function ($$v) {_vm.$set(_vm.payload, "highest", $$v)},expression:"payload.highest"}})],1)]),_c('section',{staticClass:"d-flex",staticStyle:{"gap":"12px"}},[_c('v-btn',{staticClass:"text-capitalize font-12 font-weight-700",staticStyle:{"letter-spacing":"0","width":"120px"},attrs:{"depressed":""},on:{"click":function($event){return _vm.moveTo('/badge')}}},[_c('span',[_vm._v("Batalkan")])]),(_vm.$route.params.id)?_c('v-btn',{staticClass:"text-capitalize font-12 font-weight-700",staticStyle:{"letter-spacing":"0","width":"120px"},attrs:{"depressed":"","color":"secondary","loading":_vm.isLoadingSubmit,"disabled":_vm.isDisableSubmit},on:{"click":function($event){_vm.isDialogEdit = true}}},[_c('span',[_vm._v("Terapkan Perubahan")])]):_c('v-btn',{staticClass:"text-capitalize font-12 font-weight-700",staticStyle:{"letter-spacing":"0","width":"120px"},attrs:{"depressed":"","color":"secondary","loading":_vm.isLoadingSubmit,"disabled":_vm.isDisableSubmit},on:{"click":_vm.actionSubmit}},[_c('span',[_vm._v("Terapkan Badge")])])],1)]),_c('Dialog-Edit',{attrs:{"isOpen":_vm.isDialogEdit},on:{"closeDialog":function($event){_vm.isDialogEdit = false},"actionSubmitEdit":_vm.actionSubmitEdit,"errorPassword":_vm.errorPassword}}),_c('v-snackbar',{attrs:{"timeout":3000,"color":"warning","top":"","right":""},model:{value:(_vm.isErrorUpload),callback:function ($$v) {_vm.isErrorUpload=$$v},expression:"isErrorUpload"}},[_c('span',{staticStyle:{"font-size":"12px","font-weight":"500"}},[_vm._v(_vm._s(_vm.messageErrorUpload))])]),_c('v-snackbar',{attrs:{"timeout":3000,"color":"warning","top":"","right":""},model:{value:(_vm.isErrorSubmit),callback:function ($$v) {_vm.isErrorSubmit=$$v},expression:"isErrorSubmit"}},[_c('span',{staticStyle:{"font-size":"12px","font-weight":"500"}},[_vm._v(_vm._s(_vm.messageErrorSubmit))])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }